.adSlot {
  @apply flex;

  &.alignLeft {
    @apply justify-start;
  }

  &.alignRight {
    @apply justify-end;
  }

  &.alignCenter {
    @apply justify-center;
  }
}
